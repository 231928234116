/* 
 * Authentication helper utility functions module 
 */

import axios from 'axios'


// set default headers for axios
export const setAuthToken = token => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    } else {
        delete axios.defaults.headers.common['Authorization']
    }
}


export const getAuthToken = () =>
    window.localStorage.getItem('token')


export const revokeToken = () => {
    const token = getAuthToken()
    if (!token) return false;

    const data = axios.post(
        `${process.env.API}/oauth2/revoke-token`,
        { token: token },
        { headers: { 'Authorization': `Bearer ${token}` } }
    ).then((response) => {
        console.log(">> revokeToken response: ", response);
        if (response.status !== 200) return;

    }).catch((err) => {
        console.log(">> revokeToken err: ", err);

    })
    console.log(">> returning", data);
    return data
}

// check if the client has a valid auth session already
export const checkAuthToken = async (dispatchActionCb) => {
    try {
        const token = window.localStorage.getItem('token')

        if (!token) return false;

        const { data } = await axios({
            method: 'GET',
            url: `${process.env.API}/users/me`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        })

        console.log("GOT DATA", data)

        if (!data.profile || !data.profile.contacts ||
            !data.profile.contacts.phone) {
            return false
        }

        setAuthToken(token)
        dispatchActionCb({ type: 'SAVE_USER', payload: data })

        console.log('>> checkAuthToken: Valid')
        return true;

    } catch (err) {
        console.log('Error again', err)
        return false;
    }
}


// clear the auth token and logout
export const clearAuthToken = async (dispatchActionCb) => {
    try {
        dispatchActionCb({ type: 'LOGOUT' })
        // window.localStorage.removeItem('token')
        window.localStorage.clear()
        setAuthToken(false)
        return true
    } catch (err) {
        console.log("Could not unset auth token", err)
        return false
    }
}